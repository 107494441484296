import { ICalculationItem } from 'components/calculation/CalculationContacts';

export const calcItemsNVG: ICalculationItem[][] = [
  [
    {
      title: 'Казанское шоссе, д.&nbsp;4',
      times: ['<b>08:00 — 19:00</b>, Пн-Пт', '<b>09:00 — 19:00</b>, Сб-Вс'],
    },
    {
      title: 'пр. Гагарина, д.&nbsp;35, корп.&nbsp;2',
      times: ['<b>08:00 — 19:00</b>, Пн-Пт', '<b>09:00 — 19:00</b>, Сб-Вс'],
    },
  ],
  [
    {
      title: 'ул. Чаадаева, д.&nbsp;1Н',
      times: ['<b>08:00 — 19:00</b>, Пн-Пт', '<b>09:00 — 19:00</b>, Сб-Вс'],
    },
  ],
];
