import { ICalculationItem } from 'components/calculation/CalculationContacts';

export const calcItemsCLN: ICalculationItem[][] = [
  [
    {
      title: 'пр. Казанский, д.&nbsp;148',
      times: ['<b>08:00 — 19:00</b>, Пн-Пт', '<b>09:00 — 19:00</b>, Сб-Вс'],
    },
  ],
];
