import React from 'react';
import { HeaderWrapper } from 'components/global/Wrapper/style';
import { AppHeaderLogo } from 'components/layout/AppHeaderLogo';
import { Header, HeaderContent, HeaderHero } from './style';

export function AppHeader() {
  window.addEventListener('scroll', () => {
    const friends = document.querySelector('#AppFormFriends') as HTMLElement;
    const hero = document.getElementById('hero');
    if (friends && hero) {
      const { scrollY } = window;
      const topOffset = friends.offsetTop - (friends.scrollHeight * 2);
      if (scrollY > topOffset) {
        hero.style.position = 'absolute';
        hero.style.top = `${topOffset}px`;
      } else {
        hero.style.position = 'fixed';
        hero.style.top = 'unset';
      }
    }
  });
  return (
    <Header>
      <HeaderWrapper>
        <HeaderContent>
          <AppHeaderLogo />
          <HeaderHero id="hero" className="_desktop" src="img/bg-top.png" srcSet="img/bg-top@2x.png 2x" />
          <HeaderHero className="_tablet" src="img/bg-top_tablet.png" srcSet="img/bg-top_tablet@2x.png 2x" />
          <HeaderHero className="_mobile" src="img/bg-top_mobile.png" srcSet="img/bg-top_mobile@2x.png 2x" />
        </HeaderContent>
      </HeaderWrapper>
    </Header>
  );
}
