import styled from 'styled-components';
import { device } from 'styles/mixins';

export const Footer = styled.footer`
  display: flex;
`;

export const FooterContent = styled.div`
  padding: 18px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  ${device.sm`
    justify-content: space-between;
    flex-wrap: wrap;
  `}
`;
