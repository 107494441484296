import styled, { css } from 'styled-components';
import { device } from 'styles/mixins';

export const CalculationContactsList = styled.section<{isFirst: boolean | undefined}>`
  ${(props) => (props.isFirst ? css`
        order: -1;`
    : css`
        order: 1;
    `)};
  width: 47%;
  
  ${device.md`
    width: 100%;
  `}
`;

export const CalculationContactsListTitle = styled.div<{opened: boolean | undefined}>`
  display: flex;
  align-items: center;
  margin: 20px 0 15px;
  cursor: pointer;
  width: 100%;
  
  ${device.md`
    margin: 27px 0 21px;
    max-width: 500px;
  `}

  ${(props) => (props.opened ? css`
    ${device.sm`
        margin: 41px 0 26px;
    `}`
    : css`
      ${device.sm`
        margin: 41px 0 0;
      `} \`
    `)};
  
  h3 {
    font-weight: 600;
    line-height: 19px;
    font-size: 14px;
    user-select: none;

    ${device.md`
    line-height: 21px;
    font-size: 15px;
    max-width: 500px;
  `}
  }
`;

export const CalculationContactsListArrow = styled.div<{opened: boolean | undefined}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 34px;
  ${(props) => (props.opened ? css`
        transform: rotate(0deg);`
    : css`
        transform: rotate(180deg);
    `)};
  transition: 300ms linear all;

  ${device.md`
    margin-left: 16px;
  `}
  
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const CalculationContactsListWrap = styled.section<{opened: boolean | undefined}>`
  display: flex;
  overflow: hidden;
  ${(props) => (props.opened ? css`
        height: fit-content;`
    : css`
        height: 0;
    `)};

  ${device.sm`
    flex-direction: column;
    justify-content: flex-start;
  `}
`;
