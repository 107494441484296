import styled from 'styled-components';
import { device } from 'styles/mixins';

export const Header = styled.header`
  height: ${({ theme }) => theme.sizes.header.height};
  background: ${({ theme }) => theme.colors.white90};
  z-index: 0;
  
  ${device.md`position: relative;`}


  ${device.sm`
    background: none;
    height: auto;
  `}
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${device.sm`
    flex-direction: column-reverse;
  `}
`;

export const HeaderHero = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 0;
  
  &._desktop {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    height: auto;
    width: 100vw;
    max-width: 1440px;
  }
  
  ${device.sm`
    position: static;
    width: 100vw;
  `}
`;
