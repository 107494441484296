import styled from 'styled-components';
import { device } from 'styles/mixins';

export const FooterCopyright = styled.span`
  padding-top: 10px;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.lightGray};

  ${device.md`
    padding-top: 0;
    line-height: 16px;
    font-size: 12px;
  `}

  ${device.sm`
    margin-top: 12px;
    margin-left: auto;
    margin-right: auto;
  `}
`;
