import { ICalculationItem } from 'components/calculation/CalculationContacts';

export const calcItemsPSK: ICalculationItem[][] = [
  [
    {
      title: 'Завеличенская волость, д.&nbsp;Уграда, стр&nbsp;26',
      times: ['<b>09:00 — 18:00</b>, Пн-Пт', '<b>09:00 — 16:00</b>, Сб'],
    },
  ],
];
