import styled from 'styled-components';
import { device } from 'styles/mixins';

export const FormUpload = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 30px;
  width: calc(50% - 30px);

  ${device.md`
    width: 100%;
    margin-left: 0;
    margin-bottom: 8px;
  `}
  
  .info {
    margin-left: 8px;

    ${device.sm`
        display: flex;
    `}
  }
  
  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  
  .description {
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    color: #7A8891;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${device.sm`
      margin-left: 8px;
    `}
  }
  
  input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    outline: none;
    padding: 15px 16px 13px;
    border-radius: 6px;
    background: transparent;
    line-height: 19px;
    font-size: 14px;
  }
  
  .file {
    width: 21px;
    height: 21px;
  }
`;
