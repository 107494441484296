export const radioMetalList = [
  {
    name: 'metalTileSize',
    value: 'Стандартные',
    id: 'standart',
    label: 'Стандартные',
    description: 'Произведем расчет по 3 размерам листов (длины 1,15 м; 2,25 м; 3,6 м)',
  },
  {
    name: 'metalTileSize',
    value: 'Индивидуальные',
    id: 'individual',
    label: 'Индивидуальные',
    description: 'Подберем оптимальную длину листов под размеры вашей кровли. Срок доставки уточнит менеджер.',
  },
];
