import React from 'react';
import { FooterButtons, footerButton } from 'mock/footerButtons';
import { AppIcon } from 'components/global/AppIcon';
import { FooterLogos, FooterLogoButton } from './style';

export function AppFooterLogos() {
  return (
    <FooterLogos>
      {
          FooterButtons.map((item: footerButton, index: number) => (
            <FooterLogoButton key={index} href={item.href}>
              <AppIcon name={item.icon} width={item.iconWidth} height={item.iconHeight} />
            </FooterLogoButton>
          ))
        }
    </FooterLogos>
  );
}
