import styled from 'styled-components';
import { device } from 'styles/mixins';

export const Calculation = styled.section`
  background-color: ${({ theme }) => theme.colors.white80};
`;

export const CalculationContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 47px 0 52px;

  ${device.md`
    padding: 47px 0 60px;
  `}

  ${device.sm`
    padding: 47px 0 41px;
  `}
`;

export const CalculationTitle = styled.h2`
  font-weight: 600;
  line-height: 40px;
  font-size: 30px;
  margin-bottom: 7px;

  ${device.md`
    line-height: 32px;
    font-size: 24px;
    margin-bottom: 6px;
  `}
`;

export const CalculationPrice = styled.span`
  color: ${({ theme }) => theme.colors.yellow};
  text-transform: uppercase;
  line-height: 16px;
  font-size: 12px;
  margin-bottom: 14px;

  ${device.md`
    margin-bottom: 22px;
  `}
`;

export const CalculationWrapper = styled.div`
  margin-bottom: 75px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
