import { ICalculationItem } from 'components/calculation/CalculationContacts';

export const calcItemsTVR: ICalculationItem[][] = [
  [
    {
      title: 'Октябрьский пр.,&nbsp;113',
      times: ['<b>08:00 — 20:00</b>, Пн-Вс'],
    },
  ],
];
