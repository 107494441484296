export const calculationCustomRoof = [
  {
    value: 'Металлочерепица',
    label: 'Металлочерепица',
  },
  {
    value: 'Профлист',
    label: 'Профлист',
  },
  {
    value: 'Клик-фальц',
    label: 'Клик-фальц',
  },
  {
    value: 'Гибкая битумная черепица',
    label: 'Гибкая битумная черепица',
  },
  {
    value: 'Ондулин',
    label: 'Ондулин',
  },
  {
    value: 'Битумные наплавляемые материалы',
    label: 'Битумные наплавляемые материалы',
  },
  {
    value: 'Мембрана ПВХ',
    label: 'Мембрана ПВХ',
  },
  {
    value: 'Другое',
    label: 'Другое',
  },
];

export const calculationCustomWall = [
  {
    value: 'Газобетон',
    label: 'Газобетон',
  },
  {
    value: 'Кирпич',
    label: 'Кирпич',
  },
  {
    value: 'Деревянный каркас',
    label: 'Деревянный каркас',
  },
  {
    value: 'Брус естественной влажности',
    label: 'Брус естественной влажности',
  },
  {
    value: 'Другое',
    label: 'Другое',
  },
];

export const calculationCustomFacade = [
  {
    value: 'Сайдинг ПВХ',
    label: 'Сайдинг ПВХ',
  },
  {
    value: 'Металлический сайдинг',
    label: 'Металлический сайдинг',
  },
  {
    value: 'Фасадные панели',
    label: 'Фасадные панели',
  },
  {
    value: 'Блок-хаус, имитация бруса',
    label: 'Блок-хаус, имитация бруса',
  },
  {
    value: 'Штукатурный фасад',
    label: 'Штукатурный фасад',
  },
  {
    value: 'Другое',
    label: 'Другое',
  },
];
