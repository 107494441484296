import styled from 'styled-components';
import { device } from 'styles/mixins';

export const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
  height: 100%;
  ${device.md`
    position: relative;
  `}
  ${device.sm`
    justify-content: center;
    width: 100%;
  `}
`;

export const HeaderLogoImg = styled.img`
  width: 136px;
  z-index: 0;
`;
