import React, { useState } from 'react';
import { calculationBuilding } from 'mock/calculationBuilding';
import { calculationRepair } from 'mock/calculationRepair';
import { calculationCustomRoof, calculationCustomFacade, calculationCustomWall } from 'mock/calculationCustom';
import { radioMetalList } from 'mock/formMetalRadioList';
import { FormUploadList, FormUploadListItem } from './style';
import { AppFormSelect } from '../FormSelect';
import { AppFormUpload } from '../FormUpload';
import { AppFormInput } from '../FormInput';
import { AppFormRadioList } from '../FormRadios';

interface FormUploadListProps {
  selectedType: string,
  name: string
  register?: any
  control?: any
  error?: string
}

function AppFormUploadList({
  selectedType, register, control, error, name,
}: FormUploadListProps) {
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState('');
  const [roof, setRoof] = useState('');
  return (
    <FormUploadList>
      {selectedType === 'Отдельных видов строительных работ'
          && (
          <FormUploadListItem>
            <AppFormSelect half options={calculationCustomRoof} placeholder="Материал кровли" name="roofMaterial" control={control} onChange={(e) => { setRoof(e); }} />
            <AppFormUpload description="План кровли с<br/>размерами" name="roofFile" register={register} />
            {roof === 'Металлочерепица'
                && (
                <div className="radio-wrap">
                  {error === 'required' ? (<div className="error">Пожалуйста, выберите значение</div>) : ''}
                  <AppFormRadioList
                    name={name}
                    upload
                    onChange={(e) => setValue(e)}
                    radioList={radioMetalList}
                    control={control}
                  />
                </div>

                )}
            <AppFormSelect half options={calculationCustomWall} placeholder="Материал стен" name="wallMaterial" control={control} />
            <AppFormUpload description="Размеры стен,<br/>расположение проемов" name="wallFile" register={register} />
            <AppFormSelect half options={calculationCustomFacade} placeholder="Материал фасада" name="facadeMaterial" control={control} />
            <AppFormUpload description="Размеры стен,<br/>расположение проемов" name="facadeFile" register={register} />
            <AppFormInput label="Другое" id="otherMaterial" type="text" error={false} name="otherMaterial" control={control} />
            <AppFormUpload name="otherMaterialFile" register={register} />
          </FormUploadListItem>
          )}
      {selectedType === 'Ремонта'
          && (
          <FormUploadListItem>
            <AppFormSelect half options={calculationRepair} placeholder="Объект ремонта" name="repairObject" control={control} />
            <AppFormUpload description="Обмерный план<br/>помещения и т.д" name="repairFile" register={register} />
          </FormUploadListItem>
          )}
      {selectedType === 'Нового строительства'
          && (
          <FormUploadListItem>
            <AppFormSelect half options={calculationBuilding} placeholder="Объект строительства" name="buildingObject" control={control} />
            <AppFormUpload description="проект разделы АР и КР" name="buildingFile" register={register} />
          </FormUploadListItem>
          )}

    </FormUploadList>
  );
}

AppFormUploadList.defaultProps = {
  register: '',
  control: '',
  error: '',
};

export { AppFormUploadList };
