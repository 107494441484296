import { ICalculationItem } from 'components/calculation/CalculationContacts';

export const calcItemsMSK: ICalculationItem[][] = [
  [
    {
      title: 'Балашиха, ш. Энтузиастов,<br/>вл.11, стр.2',
      times: ['<b>08:00 — 20:00</b>, ежедневно'],
    },
    {
      title: 'Красногорск, Новорижское ш.,<br/>26-й км',
      times: ['<b>08:00 — 20:00</b>, ежедневно'],
    },
  ],
  [
    {
      title: 'Московская обл.,<br/>Новорязанское ш., 23-й км, 21с1',
      times: ['<b>08:00 — 20:00</b>, ежедневно'],
    },
    {
      title: 'Московская обл., д. Грибки,<br/>Дмитровское шоссе, 56/2',
      times: ['<b>08:00 — 20:00</b>, ежедневно'],
    },
  ],
  [
    {
      title: 'Московская обл., р.п. Боброво, с67Ю',
      times: ['<b>09:00 — 21:00</b>, ежедневно'],
    },
  ],
];
