import React from 'react';
import { AppFormFields } from 'components/form/FormFields';
import {
  Form, FormContent, FormDescripiton, FormInit, FormLink,
  FormPolicy, FormText, FormTitle, FormExclusive,
  FormWrapper,
} from './style';
import { AppFormFriends } from '../FormFriends';

export function AppForm() {
  return (
    <FormWrapper>
      <Form>
        <FormContent>
          <FormExclusive>
            Эксклюзивно в Петровиче!
          </FormExclusive>
          <FormTitle>
            Профессиональный расчет с экспертом
          </FormTitle>
          <FormText>
            Отважились на строительство дома
            <br />
            или на масштабный ремонт в квартире?
          </FormText>
          <FormDescripiton>
            Оставьте заявку на
            {' '}
            <b>бесплатный</b>
            {' '}
            расчет строительных и отделочных материалов по вашему
            проекту или запишитесь на консультацию к нашему
            специалисту в строительно-торговом центре
          </FormDescripiton>
          <FormInit>
            <AppFormFields />
          </FormInit>
          <FormPolicy>
            Нажимая на кнопку «Отправить», я даю согласие на
            обработку персональных данных в соответствии с «
            <FormLink href="https://petrovich.ru/about/confidentiality/">Политикой в области обработки и защиты персональных данных</FormLink>
            ».
          </FormPolicy>
        </FormContent>
      </Form>
      <AppFormFriends />
    </FormWrapper>
  );
}
