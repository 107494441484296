export interface footerButton {
    href: string,
    icon: string,
    iconWidth: number,
    iconHeight: number
}

export const FooterButtons: Array<footerButton> = [
  {
    href: 'https://connect.ok.ru/offer?url=https%3A%2F%2Fshopexpert.petrovich.ru%2F&amp;title=%D0%9F%D1%80%D0%BE%D1%84%D0%B5%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%80%D0%B0%D1%81%D1%87%D0%B5%D1%82%20%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BC%D0%B0%D1%82%D0%B5%D1%80%D0%B8%D0%B0%D0%BB%D0%BE%D0%B2.%20%D0%AD%D0%BA%D1%81%D0%BA%D0%BB%D1%8E%D0%B7%D0%B8%D0%B2%D0%BD%D0%BE%20%D0%B2%20%D0%9F%D0%B5%D1%82%D1%80%D0%BE%D0%B2%D0%B8%D1%87%D0%B5!&amp;utm_source=share2',
    icon: 'od',
    iconWidth: 12,
    iconHeight: 20,
  },
  {
    href: 'https://twitter.com/intent/tweet?text=%D0%9F%D1%80%D0%BE%D1%84%D0%B5%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%80%D0%B0%D1%81%D1%87%D0%B5%D1%82%20%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BC%D0%B0%D1%82%D0%B5%D1%80%D0%B8%D0%B0%D0%BB%D0%BE%D0%B2.%20%D0%AD%D0%BA%D1%81%D0%BA%D0%BB%D1%8E%D0%B7%D0%B8%D0%B2%D0%BD%D0%BE%20%D0%B2%20%D0%9F%D0%B5%D1%82%D1%80%D0%BE%D0%B2%D0%B8%D1%87%D0%B5!&amp;url=https%3A%2F%2Fshopexpert.petrovich.ru%2F&amp;utm_source=share2',
    icon: 'twitter',
    iconWidth: 21,
    iconHeight: 16,
  },
  {
    href: 'https://vk.com/share.php?url=https%3A%2F%2Fshopexpert.petrovich.ru%2F&amp;title=%D0%9F%D1%80%D0%BE%D1%84%D0%B5%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%80%D0%B0%D1%81%D1%87%D0%B5%D1%82%20%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BC%D0%B0%D1%82%D0%B5%D1%80%D0%B8%D0%B0%D0%BB%D0%BE%D0%B2.%20%D0%AD%D0%BA%D1%81%D0%BA%D0%BB%D1%8E%D0%B7%D0%B8%D0%B2%D0%BD%D0%BE%20%D0%B2%20%D0%9F%D0%B5%D1%82%D1%80%D0%BE%D0%B2%D0%B8%D1%87%D0%B5!&amp;utm_source=share2',
    icon: 'vk',
    iconWidth: 29,
    iconHeight: 16,
  },
];
