export const calculationRepair = [
  {
    value: 'Квартиры',
    label: 'Квартиры',
  },
  {
    value: 'Загородного дома',
    label: 'Загородного дома',
  },
  {
    value: 'Офисного помещения',
    label: 'Офисного помещения',
  },
  {
    value: 'Коммерческого помещения',
    label: 'Коммерческого помещения',
  },
  {
    value: 'Другое',
    label: 'Другое',
  },
];
