export interface CalcItem {
    id?: number
    icon: string,
    title: string,
    description: string,
    iconWidth: number,
    iconHeight: number
}

export const calcItems: CalcItem[] = [
  {
    id: 0,
    icon: 'prof',
    title: 'ПРОФЕССИОНАЛЬНЫЙ РАСЧЕТ',
    description: 'Рассчитаем все необходимые материалы от фундамента до крыши для строительства дома по вашему проекту или ремонта квартиры',
    iconWidth: 34,
    iconHeight: 36,
  },
  {
    id: 1,
    icon: 'order',
    title: 'ЗАКАЗ ТОВАРА',
    description: 'Поможем выбрать и заказать материалы, ориентируясь на ваши вкусы и предпочтения',
    iconWidth: 43,
    iconHeight: 36,
  },
  {
    id: 2,
    icon: 'individual',
    title: 'ИНДИВИДУАЛЬНЫЙ ПОДХОД',
    description: 'Персональный менеджер с учетом специфики вашей задачи предложит оптимальное решение',
    iconWidth: 38,
    iconHeight: 36,
  },
  {
    id: 3,
    icon: 'support',
    title: 'СОПРОВОЖДЕНИЕ ЗАКАЗА',
    description: 'Эксперт всегда с Вами на связи и будет помогать на каждом этапе Вашего заказа и даже после, если потребуется дополнительная консультация',
    iconWidth: 38,
    iconHeight: 36,
  },
];
