import styled from 'styled-components';
import { device } from 'styles/mixins';

export const FormFriendsLeftIcon = styled.div`
  transform: rotate(180deg);
  position: absolute;
  top: 32px;
  left: -12px;
  width: 36px;
  height: 35px;
  ${device.sm`
    top: 14px;
    left: 12px;
    width: 22px;
    height: 22px;
  `}
`;
export const FormFriendsRightIcon = styled.div`
  position: absolute;
  bottom: 21px;
  right: 103px;
  width: 36px;
  height: 35px;
  ${device.md`
    right: 0;
  `}
  ${device.sm`
    right: 14px;
    bottom: 12px;
    width: 22px;
    height: 22px;
  `}
`;
export const FormFriendsBackdropIcon = styled.div`
  
`;

export const FormFriends = styled.div`
  position: relative;
  margin-bottom: 90px;
  max-width: 607px;
  width: 100%;
  height: 189px;
  background: #fff;
  border: 1px solid #ebf1fc;
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 38px 130px 38px 30px;

  &:after {
    content: '';
    position: absolute;
    left: -10px;
    bottom: 30px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent #fff transparent transparent;
  }
  
  ${device.md`
    padding: 45px 30px 42px 30px;
    max-width: 279px;
    height: auto;
    &:after {
      bottom: 60px;
    }
  `}
  
  ${device.sm`
    padding: 21px 15px 16px 15px;
    margin-bottom: 12px;
    max-width: 100%;
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    &:after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: calc(50% - (20px / 2));
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: #fff transparent transparent transparent;
    } 
  `}
`;
export const FormFriendsTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  
  ${device.md`
    font-size: 14px;
    line-height: 18px;
  `}
  
  ${device.sm`
    text-align: center;
  `}
`;
export const FormFriendsDescription = styled.div`
  font-size: 14px;
  line-height: 22px;

  ${device.md`
    font-size: 12px;
    line-height: 18px;
    
    br {
      display: none;
     }
  `}

  ${device.sm`
    text-align: center;
  `}
`;
export const FormFriendsBackdrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 103px;
  height: 100%;
  background: #e6f2f9;
  border-radius: 0 10px 10px 0;

  ${device.md`
    display: none;
  `}
`;
