import styled, { css } from 'styled-components';
import { device } from 'styles/mixins';

export const CalculationContacts = styled.section`
  width: 100%;
`;

export const CalculationContactsTitle = styled.h3`
  font-weight: 600;
  line-height: 19px;
  font-size: 14px;
  margin: 35px 0 18px;

  ${device.md`
    line-height: 21px;
    font-size: 15px;
    margin: 27px 0 21px;
    max-width: 500px;
  `}

  ${device.sm`
    margin: 46px 0 25px;
  `}
`;

export const CalculationContactsList = styled.section`
  display: flex;
  flex-wrap: wrap;

  ${device.sm`
    flex-direction: column;
    justify-content: flex-start;
  `}
`;

export const CalculationsListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const CalculationTabs = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;

  ${device.md`
    display: none;
  `}
`;

export const CalculationTabName = styled.h2<{active: boolean}>`
  ${(props) => (props.active ? css`
    color: ${({ theme }) => theme.colors.black};`
    : css`
      color: ${({ theme }) => theme.colors.disabled};
    `)}
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  user-select: none;
  cursor: pointer;
`;

export const CalculationDivider = styled.div`
  width: 1px;
  height: 26px;
  background: ${({ theme }) => theme.colors.black};
  margin: 0 15px;
`;

export const CalculationsAdressCol = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

export const CalculationsMobileTitle = styled.h3`
  display: none;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 70px;

  ${device.md`
    display: flex;
  `}
`;

export const CalculationsWrap = styled.div`
  section {
    width: 100%;
  }
`;

export const CalculationsIframe = styled.div`
  margin-top: 20px;
  max-width: 550px;
  width: 100%;


  iframe {
    border: none;
    height: 710px;
    max-width: 540px;
    width: 100%;
  }

  ${device.md`
    margin-top: 35px;
  `}

  ${device.m`
    width: 100%;
    display: flex;
    justify-content: center;
  `}
`;

export const CalculationsIframeWrap = styled.div`
  background-color: white;
  padding: 5px;
  height: 720px;

  ${device.m`
    padding: 2px;
    width: 100%;
    height: 711px;
  `}
`;
