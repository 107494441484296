import React, { useContext, useEffect, useState } from 'react';
import { AppCalculationContactsItem } from 'components/calculation/CalculationContactsItem';
import { ICalculationItem } from 'components/calculation/CalculationContacts';
import { AppIcon } from 'components/global/AppIcon';
import CityContext from 'context/cityContext';
import Cookies from 'universal-cookie';
import { supportedCities } from 'mock/supportedCitites';
import {
  CalculationContactsList,
  CalculationContactsListWrap,
  CalculationContactsListTitle,
  CalculationContactsListArrow,
} from './style';

export interface CalculationContactListProps {
    title: string
    items: ICalculationItem[][],
    activeByDefault?: boolean,
    isCurrent?: boolean,
}

export function AppCalculationContactsList({
  title, items, activeByDefault, isCurrent,
}: CalculationContactListProps) {
  const [isOpen, setIsOpen] = useState<boolean | undefined>(activeByDefault);
  const [canFetch, setCanFetch] = useState(true);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { city } = useContext(CityContext);
  const cookies = new Cookies();

  function init() {
    const fetchedCityCookie = cookies.get('fetched-city');
    const pickedCityCookie = cookies.get('picked-city');
    const lookupCity = pickedCityCookie || fetchedCityCookie || city;
    if (supportedCities.includes(lookupCity) ? title.includes(lookupCity) : title.includes('Санкт-Петербург')) {
      setIsOpen(true);
    }
  }

  useEffect(() => {
    // таймаут нужен для того, чтобы успел обновиться город
    setTimeout(() => {
      if (canFetch) {
        init();
        setCanFetch(false);
      }
    }, 1000);
    setIsOpen(activeByDefault);
  }, [activeByDefault]);

  return (
    <CalculationContactsList isFirst={isCurrent}>
      <CalculationContactsListTitle
        opened={isOpen}
        onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
      >
        <h3>
          {title}
        </h3>
        <CalculationContactsListArrow as={AppIcon} name="arrow" opened={isOpen} />
      </CalculationContactsListTitle>
      {
              items.map((list, itemsIndex) => (
                <CalculationContactsListWrap key={itemsIndex} opened={isOpen}>
                  {
                          Object.values(list).map((item, listIndex) => (
                            <AppCalculationContactsItem
                              key={listIndex}
                              title={item.title}
                              times={item.times}
                            />
                          ))
                      }
                </CalculationContactsListWrap>
              ))
          }
    </CalculationContactsList>
  );
}
AppCalculationContactsList.defaultProps = {
  activeByDefault: false,
  isCurrent: false,
};
