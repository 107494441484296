export const partnerCities: Array<string> = [
  'Чебоксары',
  'Набережные Челны',
  'Киров',
  'Казань',
  'Нижний Новгород',
  'Псков',
  'Саратов',
  'Сыктывкар',
];
