export const calculationBuilding = [
  {
    value: 'Загородного дома',
    label: 'Загородного дома',
  },
  {
    value: 'Коммерческой недвижимости',
    label: 'Коммерческой недвижимости',
  },
  {
    value: 'Муниципального объекта',
    label: 'Муниципального объекта',
  },
  {
    value: 'Другое',
    label: 'Другое',
  },
];
