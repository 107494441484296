import React from 'react';
import { AppFormRadio, IAppFormRadioProps } from 'components/form/FormRadio';
import { FormRadios } from './style';

interface IAppFormRadioListProps {
  radioList: IAppFormRadioProps[]
  name: string
  control?: any
  error?: string
  upload?: boolean
  // eslint-disable-next-line no-unused-vars
  onChange: (e: React.SetStateAction<string>) => void
}

export const AppFormRadioList = React.forwardRef(({
  radioList, control, error, onChange, upload, name,
// eslint-disable-next-line no-unused-vars
}: IAppFormRadioListProps, ref) => {
  const text = 'Рассчитать материалы для:';
  const handleChange = (e: React.SetStateAction<string>) => {
    onChange(e);
  };
  return (
    <FormRadios upload={!!upload}>
      <p>{text}</p>
      {error === 'required' ? (<div className="error">Пожалуйста, выберите значение</div>) : ''}
      {
        radioList.map((radio, index) => (
          <AppFormRadio
            key={index}
            id={radio.id}
            control={control}
            description={radio.description}
            name={name}
            label={radio.label}
            value={radio.label}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        ))
      }
    </FormRadios>
  );
});

AppFormRadioList.defaultProps = {
  control: '',
  error: '',
  upload: false,
};
