import styled, { css } from 'styled-components';
import { device } from 'styles/mixins';

export const FormSelect = styled.div<{half: boolean}>`
  width: 100%;
  
  ${(props) => (props.half ? css`
    width: calc(100% / 2 - 7px);`
    : css`
      width: 100%;
    `)}
  margin-bottom: 10px;

  ${device.md`
    width: 100% !important;
  `}
  
  .faux-input {
    position: absolute;
    opacity: 0;
  }
  
  .error {
    font-weight: 500;
    line-height: 16px;
    font-size: 12px;
    color: #ed1c24;
  }
`;
