export const radioList = [
  {
    name: 'calculationType',
    value: 'Ремонта',
    id: 'calculateTwo',
    label: 'Ремонта',
  },
  {
    name: 'calculationType',
    value: 'Нового строительства',
    id: 'calculateThree',
    label: 'Нового строительства',
  },
  {
    name: 'calculationType',
    value: 'Отдельных видов строительных работ',
    id: 'calculateOne',
    label: 'Отдельных видов строительных работ',
  },
  {
    name: 'calculationType',
    value: 'Другое',
    id: 'calculateFour',
    label: 'Другое',
  },
];
