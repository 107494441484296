import React, { ChangeEvent } from 'react';
import { Controller } from 'react-hook-form';
import { FormRadio } from './style';

export interface IAppFormRadioProps {
    id: string
    name: string
    label: string
    value: string
    control?: any
    description?: string
    // eslint-disable-next-line no-unused-vars
    onChange?: (e: string) => void
}

function AppFormRadio(props: IAppFormRadioProps | Readonly<IAppFormRadioProps>) {
  const {
    name, id, label, description, control, value,
  } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => { // { target: { value } }
    const { onChange } = props;
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <FormRadio>
      <Controller
        control={control}
        rules={{ required: true }}
        name={name || ''}
        render={({ field }) => (
          <input
            {...field}
            type="radio"
            name={name}
            id={id}
            value={value}
            onChange={(e) => {
              if (e) {
                handleChange(e);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                field.onChange(e.target.value);
              }
            }}
          />
        )}
      />

      <div className="text">
        <span>{label}</span>
        {
          description && <span className="description">{description}</span>
        }
      </div>
      <div className="checkmark" />
    </FormRadio>
  );
}

AppFormRadio.defaultProps = {
  control: '',
};

export { AppFormRadio };
