import React from 'react';
import { FooterCopyright } from './style';

export function AppFooterCopyright() {
  const currentYear = new Date().getFullYear();
  return (
    <FooterCopyright>
      &copy;&nbsp;
      {currentYear}
      {' '}
      Петрович
    </FooterCopyright>
  );
}
