import React from 'react';
import { Helmet } from 'react-helmet';

export function AppHead() {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no" />
      <link data-n-head="ssr" rel="icon" type="image/png" href="/favicon.ico" />
      <title>Профессиональный расчет стройматериалов. Эксклюзивно в Петровиче!</title>
      <meta property="og:image" content="https://shopexpert.petrovich.ru/local/templates/main/img/bg-top.png" />
      <meta property="og:title" content="Профессиональный расчет стройматериалов. Эксклюзивно в Петровиче!" />
      <meta property="og:description" content="Бесплатный расчет строительных и отделочных материалов по Вашему проекту" />
      <script src="https://cdn.jsdelivr.net/gh/quetzal19/panfilov.digital/dist/vanilla/index.js" />
    </Helmet>
  );
}
