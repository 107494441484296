export const cities = [
  {
    value: 'spb',
    label: 'Санкт-Петербург',
  },
  {
    value: 'msk',
    label: 'Москва',
  },
  {
    value: 'tvr',
    label: 'Тверь',
  },
  {
    value: 'gtn',
    label: 'Гатчина',
  },
  {
    value: 'vbg',
    label: 'Выборг',
  },
  {
    value: 'lug',
    label: 'Луга',
  },
  {
    value: 'kgs',
    label: 'Кингисепп',
  },
  {
    value: 'nvr',
    label: 'Великий Новгород',
  },
  {
    value: 'pzv',
    label: 'Петрозаводск',
  },
  {
    value: 'krv',
    label: 'Киров',
  },
  {
    value: 'nch',
    label: 'Набережные Челны',
  },
  {
    value: 'nn',
    label: 'Нижний Новгород',
  },
  {
    value: 'psk',
    label: 'Псков',
  },
  {
    value: 'skr',
    label: 'Сыктывкар',
  },
  {
    value: 'chr',
    label: 'Чебоксары',
  },
  {
    value: 'eng',
    label: 'Энгельс',
  },
  {
    value: 'kzn',
    label: 'Казань',
  },
];
