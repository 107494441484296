import styled, { css } from 'styled-components';

export const Modal = styled.div<{show: boolean}>`

  ${(props) => (props.show ? css`
    display: flex;`
    : css`
      display: none;
    `)}
  
  
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
`;

export const ModalWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 470px;
  height: 257px;
  z-index: 10;
`;

export const ModalTitle = styled.h2<{success: boolean}>`

  text-align: center;
  font-weight: 800;
  font-size: 30px;
  margin-bottom: 25px;
  max-width: 355px;
  
  ${(props) => (props.success ? css`
    color: ${({ theme }) => theme.colors.yellow};`
    : css`
      color: ${({ theme }) => theme.colors.error};
    `)}
`;

export const ModalDescription = styled.p<{success: boolean}>`
  font-size: 16px;
  text-align: center;
  color: #b0b0b0;

  ${(props) => (props.success ? css`
    max-width: 330px;`
    : css`
      max-width: 360px;;
    `)}
`;

export const ModalClose = styled.div<{onClick: any}>`
  position: absolute;
  top: 29px;
  right: 27px;
  width: 21px;
  height: 21px;
  cursor: pointer;
`;

export const ModalBackground = styled.div<{onClick: any}>`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.blackTransparent};
`;
