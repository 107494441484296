import styled from 'styled-components';

export const FormTextarea = styled.textarea`
  width: 100%;
  outline: none;
  padding: 15px 16px 13px;
  border: 1px solid #c4d2d9;
  border-radius: 6px;
  background: transparent;
  line-height: 19px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  color: #000;

  &::placeholder {
    color: #7a8891;
  }
`;
