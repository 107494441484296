import React, { useContext, useEffect, useState } from 'react';
import { calcItemsSPB } from 'mock/contactsDataSPB';
import { calcItemsMSK } from 'mock/contactsDataMSK';
import { calcItemsTVR } from 'mock/contactsDataTvr';
import { calcItemsCBK } from 'mock/contactsDataCBK';
import { calcItemsNVG } from 'mock/contactsDataNVG';
import { calcItemsCLN } from 'mock/contactsDataCLN';
import { calcItemsKRV } from 'mock/contactsDataKRV';
import { calcItemsPSK } from 'mock/contactsDataPSK';
import { calcItemsSRT } from 'mock/contactsDataSRT';
import { calcItemsKZN } from 'mock/contactsDataKZN';
import { calcItemsSVT } from 'mock/contactsDataSVT';
import { partnerCities } from 'mock/partnerCities';
import { AppCalculationContactsList } from 'components/calculation/CalculationContactList';
import Cookies from 'universal-cookie';
import CityContext from 'context/cityContext';
import {
  CalculationContacts,
  CalculationsListWrap,
  CalculationDivider,
  CalculationTabName,
  CalculationTabs,
  CalculationsAdressCol,
  CalculationsMobileTitle,
  CalculationsWrap,
  CalculationsIframe,
  CalculationsIframeWrap,
} from './style';

export interface ICalculationItem {
  title: string
  times: string[],
}

export function AppCalculationContacts() {
  const [selectedTab, setSelectedTab] = useState('off');
  const [currentWidth, setcurrentWidth] = useState(window.innerWidth);
  // const [canFetch, setCanFetch] = useState(true);
  const cookies = new Cookies();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { city } = useContext(CityContext);

  function lookupCity() {
    const fetchedCityCookie = cookies.get('fetched-city');
    const pickedCityCookie = cookies.get('picked-city');
    return pickedCityCookie || fetchedCityCookie || city;
  }

  function init() {
    const lookup = lookupCity();

    if (partnerCities.includes(lookup)) {
      setSelectedTab('partner');
    } else {
      setSelectedTab('off');
    }
  }

  const updateWindowDimensions = () => {
    setcurrentWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);
    init();
  }, [lookupCity()]);

  return (
    <CalculationContacts>
      <CalculationTabs>
        <CalculationTabName onClick={() => setSelectedTab('off')} active={selectedTab === 'off'}>
          Строительные центры Петровича
        </CalculationTabName>
        <CalculationDivider />
        <CalculationTabName onClick={() => setSelectedTab('partner')} active={selectedTab === 'partner'}>
          Офисы партнёров Петровича
        </CalculationTabName>
      </CalculationTabs>
      {
        (selectedTab === 'off' || (currentWidth < 1220))
        && (
        <CalculationsListWrap>
          <CalculationsMobileTitle>
            Строительные центры Петровича
          </CalculationsMobileTitle>
          <CalculationsAdressCol>
            <CalculationsWrap>
              <AppCalculationContactsList activeByDefault={lookupCity().includes('Санкт-Петербург') || lookupCity().includes('Гатчина')} isCurrent={lookupCity().includes('Санкт-Петербург') || lookupCity().includes('Гатчина')} title="Санкт-Петербург и&nbsp;Ленинградская область" items={calcItemsSPB} />
              <AppCalculationContactsList activeByDefault={lookupCity().includes('Москва')} isCurrent={lookupCity().includes('Москва')} title="Москва и Московская область" items={calcItemsMSK} />
              <div style={{ marginBottom: '55px' }}>
                <AppCalculationContactsList activeByDefault={lookupCity().includes('Тверь')} isCurrent={lookupCity().includes('Тверь')} title="Тверь" items={calcItemsTVR} />
              </div>
            </CalculationsWrap>
            <CalculationsIframe>
              <CalculationsIframeWrap>
                <iframe title="preregistration" src="https://rqms.petrovich.ru/preregistration/v1/?key=f22eac52-2a1c-4a6e-a39b-e7a2dd0ec118" />
              </CalculationsIframeWrap>
            </CalculationsIframe>
          </CalculationsAdressCol>
        </CalculationsListWrap>
        )
      }
      {
        (selectedTab === 'partner' || (currentWidth < 1220))
        && (
          <CalculationsListWrap>
            <CalculationsMobileTitle>
              Офисы партнёров Петровича
            </CalculationsMobileTitle>
            <CalculationsAdressCol>
              <AppCalculationContactsList activeByDefault={lookupCity().includes('Казань')} isCurrent={lookupCity().includes('Казань')} title="Казань" items={calcItemsKZN} />
              <AppCalculationContactsList activeByDefault={lookupCity().includes('Киров')} isCurrent={lookupCity().includes('Киров')} title="Киров" items={calcItemsKRV} />
              <AppCalculationContactsList activeByDefault={lookupCity().includes('Нижний Новгород')} isCurrent={lookupCity().includes('Нижний Новгород')} title="Нижний Новгород" items={calcItemsNVG} />
              <AppCalculationContactsList activeByDefault={lookupCity().includes('Набережные Челны')} isCurrent={lookupCity().includes('Набережные Челны')} title="Набережные Челны" items={calcItemsCLN} />
              <AppCalculationContactsList activeByDefault={lookupCity().includes('Псков')} isCurrent={lookupCity().includes('Псков')} title="Псковский район" items={calcItemsPSK} />
              <AppCalculationContactsList activeByDefault={lookupCity().includes('Саратов')} isCurrent={lookupCity().includes('Саратов')} title="Саратовская область" items={calcItemsSRT} />
              <AppCalculationContactsList activeByDefault={lookupCity().includes('Сыктывкар')} isCurrent={lookupCity().includes('Сыктывкар')} title="Сыктывкар" items={calcItemsSVT} />
              <AppCalculationContactsList activeByDefault={lookupCity().includes('Чебоксары')} isCurrent={lookupCity().includes('Чебоксары')} title="Чебоксары" items={calcItemsCBK} />
            </CalculationsAdressCol>
          </CalculationsListWrap>
        )
      }
    </CalculationContacts>
  );
}
