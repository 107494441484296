import styled from 'styled-components';

export const FormRadio = styled.label`
  cursor: pointer;
  margin-top: 8px;
  position: relative;
  display: flex;
  align-items: center;
  
  input {
    opacity: 0;
    margin-right: 16px;
    width: auto !important;

    &:checked ~ .checkmark:after {
      content: '';
      position :absolute;
      left: calc(50% - 6px);
      top: calc(50% - 6px);
      width: 12px;
      height: 12px;
      background: #F6B615;
      border-radius: 50%;
    }
  }
  
  .text {
    display: flex;
    flex-direction: column;
  }
  
  .description {
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    color: #7a8891;
    max-width: 241px;
  }
  
  .checkmark {
    position: absolute;
    left: 0;
    top: 1px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #C4D2D9;
  }
`;
