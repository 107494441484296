import { baseTheme } from 'styles/theme';

/**
 * Миксин для генерации медиазапросов
 * @Array baseTheme.media - массив брейкпоинтов в px
 * @return Array - массив сгенерированных медиазапросов для брейкпоинтов,
 * заведенных в глобальной теме
 */
export const device = (Object.keys(baseTheme.media) as Array<keyof typeof baseTheme.media>).reduce(
  (acc, key) => {
    acc[key] = (style: TemplateStringsArray) => `@media (max-width: ${baseTheme.media[key]}) { ${style} }`;
    return acc;
  },
  // eslint-disable-next-line no-unused-vars
  {} as { [index: string]: (style: TemplateStringsArray) => string },
);
