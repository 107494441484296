import styled, { css } from 'styled-components';

export const FormRadios = styled.div<{upload: boolean}>`
  width: 100%;
  ${(props) => (props.upload ? css`
    margin-bottom: 28px;`
    : css`
      margin: 24px 0 32px;
    `)}
  
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black};
  
  .error {
    font-weight: 500;
    line-height: 16px;
    font-size: 12px;
    color: #ed1c24;
  }
  
  p {
    margin: 0;
  }
`;
