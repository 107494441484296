import styled from 'styled-components';
import { device } from 'styles/mixins';

export const FooterRatio = styled.div`
  display: flex;
  align-items: center;
  height: 37px;
  margin-left: auto;

  ${device.sm`
    margin-top: 12px;
    margin-right: auto;
  `}
`;
