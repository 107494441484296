import React from 'react';
import { AppIcon } from 'components/global/AppIcon';
import {
  FormFriends, FormFriendsDescription,
  FormFriendsTitle, FormFriendsBackdrop,
  FormFriendsLeftIcon, FormFriendsRightIcon,
  FormFriendsBackdropIcon,
} from './style';

export function AppFormFriends() {
  return (
    <FormFriends id="AppFormFriends">
      <FormFriendsTitle>Друзья!</FormFriendsTitle>
      <FormFriendsDescription>
        Поделитесь с нами обмерным планом помещения,
        {' '}
        <br />
        проектом загородного дома или чертежом с указанием всех
        {' '}
        <br />
        размеров. И мы предоставим вам оперативный и точный
        {' '}
        <br />
        расчет необходимых материалов по вашему запросу.
      </FormFriendsDescription>
      <FormFriendsLeftIcon as={AppIcon} name="kov" />
      <FormFriendsRightIcon as={AppIcon} name="kov" />
      <FormFriendsBackdrop>
        <FormFriendsBackdropIcon as={AppIcon} name="sketch" width={58} height={58} />
      </FormFriendsBackdrop>
    </FormFriends>
  );
}
