import { ICalculationItem } from 'components/calculation/CalculationContacts';

export const calcItemsSRT: ICalculationItem[][] = [
  [
    {
      title: 'Энгельсский р-н, п.&nbsp;Пробуждение, пр.&nbsp;Энтузиастов, д.&nbsp;11',
      times: ['<b>08:30 — 19:00</b>, Пн-Пт', '<b>08:30 — 16:00</b>, Сб'],
    },
  ],
];
