import styled, { css } from 'styled-components';
import { device } from 'styles/mixins';

export const FormInput = styled.div<{ error: boolean}>`
  position: relative;
  
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  
  input {
    width: 100%;
    outline: none;
    padding: 15px 16px 13px;
    overflow: visible;
    margin: 0;
    ${(props) => (props.error ? css`
      border: 1px solid ${({ theme }) => theme.colors.error} !important;
    ` : css`
      border: 1px solid ${({ theme }) => theme.colors.gray};
    `)}
    border-radius: 6px;
    background: transparent;
    line-height: 19px;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.black};

    
    
    &:focus {
      & ~ label {
        top: 0;
        left: 16px;
        font-size: 12px;
      }
    }
    
    &:not([value=""]) {
      & ~ label {
        top: 0;
        left: 16px;
        font-size: 12px;
      }
    }
  }
  
  label {
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.darkGray};
    position: absolute;
    pointer-events: none;
    top: 16px;
    left: 20px;
    transition: all .2s ease;
  }
  
  div {
    font-weight: 500;
    line-height: 16px;
    font-size: 12px;
    color: #ed1c24;
  }

  ${device.md`
    width: 100%;
  `}
`;
