export const formYouAre = [
  {
    value: 'Частным лицом',
    label: 'Частным лицом',
  },
  {
    value: 'Профессиональным строителем',
    label: 'Профессиональным строителем',
  },
  {
    value: 'Дизайнером, архитектором, проектировщиком',
    label: 'Дизайнером, архитектором, проектировщиком',
  },
  {
    value: 'Другое',
    label: 'Другое',
  },
];
