import React from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from 'styles/global';
import GlobalFonts from 'assets/font/fonts';
import { AppHead } from 'components/global/AppHead';
import { AppHeader } from 'components/layout/AppHeader';
import { baseTheme } from 'styles/theme';
import { AppFooter } from 'components/layout/AppFooter';
import { AppForm } from 'components/form/Form';
import { AppCalculation } from 'components/calculation/Calculation';
import { CityProvider } from 'context/cityContext';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-P9G6MH',
};

TagManager.initialize(tagManagerArgs);

export function App() {
  return (
    <div className="page">
      <CityProvider>
        <ThemeProvider theme={baseTheme}>
          <GlobalStyles />
          <GlobalFonts />
          <AppHead />
          <AppHeader />
          <AppForm />
          <AppCalculation />
          <AppFooter />
        </ThemeProvider>
      </CityProvider>
    </div>
  );
}
