import React, { useContext, useEffect, useState } from 'react';
import { FooterWrapper } from 'components/global/Wrapper/style';
import { AppFooterLogos } from 'components/layout/AppFooterLogos';
import { AppFooterCopyright } from 'components/layout/AppFooterCopyright';
import { AppFooterRatio } from 'components/layout/AppFooterRatio';
import CityContext from 'context/cityContext';
import Cookies from 'universal-cookie';
import { Footer, FooterContent } from './style';

export function AppFooter() {
  // eslint-disable-next-line @typescript-eslint/ban-types,@typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { pickCity } = useContext(CityContext);
  const [canFetch, setCanFetch] = useState(true);
  const cookies = new Cookies();
  const fetchCity = async () => {
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/api/geoip/`, {
        method: 'GET',
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_AUTH_BASIC}`,
          Accept: '*/*',
        },
        redirect: 'follow',
      }).then((response) => response.json()).then((data) => {
        pickCity(data.cityName);
        cookies.set('fetched-city', data.cityName, { maxAge: 60 * 60 * 24 * 30 });
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (canFetch) {
      fetchCity();
    }
    setCanFetch(false);
  });
  return (
    <Footer>
      <FooterWrapper>
        <FooterContent>
          <AppFooterLogos />
          <AppFooterCopyright />
          <AppFooterRatio />
        </FooterContent>
      </FooterWrapper>
    </Footer>
  );
}
