import React from 'react';
import { AppIcon } from 'components/global/AppIcon';
import { CalcItem } from 'mock/calculationData';
import {
  CalculationItem, CalculateItemTitle, CalculateItemDescription, CalculationItemContent,
} from './style';

export function AppCalculationItem(props: CalcItem) {
  const {
    icon, iconWidth, iconHeight, title, description,
  } = props;
  return (
    <CalculationItem>
      <AppIcon name={icon} width={iconWidth} height={iconHeight} />
      <CalculationItemContent>
        <CalculateItemTitle>{title}</CalculateItemTitle>
        <CalculateItemDescription>{description}</CalculateItemDescription>
      </CalculationItemContent>
    </CalculationItem>
  );
}
