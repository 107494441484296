export const addresses = [
  {
    value: 'spb_kad',
    label: 'Ленинградская обл., КАД, 21 км, между Выборгским ш. и пр.Энгельса',
  },
  {
    value: 'spb_tal',
    label: 'г. Санкт-Петербург, Таллинское шоссе, 155, корп. 1, стр.1',
  },
  {
    value: 'spb_ind',
    label: 'г. Санкт-Петербург, Индустриальный пр., 73',
  },
  {
    value: 'spb_eng',
    label: 'г. Санкт-Петербург, Энгельса, 157 А',
  },
  {
    value: 'spb_sof',
    label: 'г. Санкт-Петербург, Софийская, 59, корп. 2, стр.1',
  },
  {
    value: 'spb_msk',
    label: 'г. Санкт-Петербург, Московское ш., 304',
  },
  {
    value: 'spb_pla',
    label: 'г. Санкт-Петербург, ул. Планерная, 15 В',
  },
  {
    value: 'spb_mur',
    label: 'Ленинградская обл., 12-13 км шоссе Кола, стр. 3',
  },
  {
    value: 'msk_sim',
    label: 'Московская обл., р.п. Боброво, с67Ю',
  },
  {
    value: 'msk_gbk',
    label: 'Московская обл., д. Грибки, Дмитровское шоссе, 56/2',
  },
  {
    value: 'msk_krs',
    label: 'Московская обл., Красногорск, Новорижское ш., 26-й км',
  },
  {
    value: 'msk_nov',
    label: 'Московская обл., Новорязанское ш., 23-й км, 21с1',
  },
  {
    value: 'msk_ent',
    label: 'Московская обл., Балашиха, ш. Энтузиастов, вл. 11, стр. 2.',
  },
  {
    value: 'tvr_okt',
    label: 'Тверь, Октябрьский пр., 113',
  },
  {
    value: 'gtn_pro',
    label: 'Гатчина, Промзона № 1, квартал 6, площадка 1',
  },
  {
    value: 'vbg_vod',
    label: 'Выборг, ул. Водного колодца, 1',
  },
  {
    value: 'lug_kom',
    label: 'Луга, Комсомольский проспект, 54 А',
  },
  {
    value: 'kgs_dor',
    label: 'Кингисепп, ул. Дорожников, 14',
  },
  {
    value: 'nvr_sir',
    label: 'Великий Новгород, Сырковское шоссе, 29',
  },
  {
    value: 'pzv_sol',
    label: 'Петрозаводск, Соломенское ш, 5с2',
  },
  {
    value: 'krv_mld',
    label: 'Киров, ул. Молодой Гвардии, д. 63',
  },
  {
    value: 'krv_ses',
    label: 'Киров, ул. Северо-Садовая, д. 16А',
  },
  {
    value: 'nch_kaz',
    label: 'Набережные Челны, пр. Казанский, д. 148',
  },
  {
    value: 'nn_kaz',
    label: 'Н. Новгород, Казанское шоссе, д. 4',
  },
  {
    value: 'nn_gag',
    label: 'Н. Новгород, пр. Гагарина, д. 35, корп. 2',
  },
  {
    value: 'nn_chd',
    label: 'Н. Новгород, ул. Чаадаева, д.1Н',
  },
  {
    value: 'psk_ugr',
    label: 'Псковский р-н, Завеличенская волость, д. Уграда, стр. 26',
  },
  {
    value: 'skr_okt',
    label: 'Сыктывкар, пр. Октябрьский, д. 131/4',
  },
  {
    value: 'chr_ish',
    label: 'Чебоксары, Ишлейский проезд, д. 11',
  },
  {
    value: 'eng_sar',
    label: 'Саратовская область, Энгельсский р-н, п. Пробуждение, пр. Энтузиастов, д. 11',
  },
  {
    value: 'kzn_rod',
    label: 'Казань, ул. Родины, д. 7Б',
  },
];
