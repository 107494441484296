import { createGlobalStyle } from 'styled-components';
import { device } from 'styles/mixins';

export default createGlobalStyle`
  
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    outline: none;
  }

  body {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: normal;
  }
  
  h2 {
    line-height: 1.15;
  }

  p {
    margin: 0;
  }

  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  input,
  textarea {
    border: 0;
    padding: 0;
    margin: 0;
  }
  
  .page {
    max-width: 1440px;
    min-width: 320px;
    margin: 0 auto;
    min-height: 100%;
  }

  &._desktop {
      ${device.md`
        display: none;
      `}
      ${device.sm`
        display: none;
      `}
  }

  &._tablet {
    @media(min-width: 1220px) {
      display: none;
    }
    ${device.sm`
        display: none;
    `}
  }
  
  &._mobile {
    @media(min-width: 768px) {
      display: none;
    }
  }

  &._desktop-and-tablet {
    ${device.sm`
        display: none;
    `}
  }

  &._desktop-and-tablet {
    @media(min-width: 768px) {
      display: none;
    }
  }

  &._hide {
    display: none;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
`;
