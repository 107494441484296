import { ICalculationItem } from 'components/calculation/CalculationContacts';

export const calcItemsKZN: ICalculationItem[][] = [
  [
    {
      title: 'ул.&nbsp;Родины, д.&nbsp;7Б',
      times: ['<b>08:00 — 17:00</b>, Пн-Пт', '<b>08:00 — 13:00</b>, Сб'],
    },
  ],
];
