import { ICalculationItem } from 'components/calculation/CalculationContacts';

export const calcItemsSVT: ICalculationItem[][] = [
  [
    {
      title: 'пр. Октябрьский, д.&nbsp;131/4',
      times: ['<b>09:00 — 21:00</b>, ежедневно'],
    },
  ],
];
