import styled from 'styled-components';

export const CalculationContactsItem = styled.div`
  min-width: 270px;
  line-height: 20px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const CalculationContactsItemTitle = styled.h4`
  margin-bottom: 8px;
`;

export const CalculationContactsItemTime = styled.span`
  display: flex;
  align-items: center;
  
  &:not(:first-of-type) {
    span {
      margin-left: 30px;
    }
  }
`;
