import React from 'react';
import { AppIcon } from 'components/global/AppIcon';
import {
  Modal, ModalWrapper, ModalTitle, ModalDescription, ModalClose, ModalBackground,
} from './style';

interface ModalProps {
    success: boolean
    show: boolean
    // eslint-disable-next-line no-unused-vars
    onChange: (e: React.SetStateAction<boolean>) => void
}

export function AppModal({
  success, show, onChange,
}: ModalProps) {
  const title = success ? 'Ваша заявка успешно отправлена!' : 'Что-то пошло не так!';
  const description = success ? 'С вами свяжется наш специалист.' : 'Не удалось отправить заявку, попробуйте еще раз немного позже.';
  return (
    <Modal show={show}>
      <ModalWrapper>
        <ModalClose onClick={() => onChange(false)} as={AppIcon} name="close" />
        <ModalTitle success={success}>{title}</ModalTitle>
        <ModalDescription success={success}>{description}</ModalDescription>
      </ModalWrapper>
      <ModalBackground onClick={() => onChange(false)} />
    </Modal>
  );
}
