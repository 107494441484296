import { ICalculationItem } from 'components/calculation/CalculationContacts';

export const calcItemsSPB: ICalculationItem[][] = [
  [
    {
      title: 'Лен. обл., КАД, 21-й км, между<br/>Выборгским ш. и&nbsp;пр. Энгельса',
      times: ['<b>08:00 — 20:00</b>, ежедневно'],
    },
    {
      title: 'Лен.&nbsp;обл., 12-13 км шоссе Кола,<br/>стр.&nbsp;3',
      times: ['<b>08:00 — 20:00</b>, ежедневно'],
    },
  ],
  [
    {
      title: 'СПб, Индустриальный пр.,&nbsp;73',
      times: ['<b>08:00 — 20:00</b>, ежедневно'],
    },
    {
      title: 'СПб, пр. Энгельса, 157&nbsp;А',
      times: ['<b>08:00 — 20:00</b>, ежедневно'],
    },
  ],
  [
    {
      title: 'СПб, ул. Софийская, 59, к.2, стр.1',
      times: ['<b>08:00 — 20:00</b>, ежедневно'],
    },
    {
      title: 'СПб, Таллинское ш., 155, к.1, стр.1',
      times: ['<b>08:00 — 20:00</b>, ежедневно'],
    },
  ],
  [
    {
      title: 'СПб, Московское ш.,&nbsp;304',
      times: ['<b>08:00 — 17:00</b>, Вт-Сб'],
    },
    {
      title: 'Гатчина, ул.&nbsp;Индустриальная,&nbsp;45',
      times: ['<b>09:00 — 18:00</b>, Вт-Сб'],
    },
  ],
  [
    {
      title: 'СПб, ул. Планерная, 15 В',
      times: ['<b>10:00 — 19:00</b>, Пн-Пт'],
    },
  ],
];
