import styled from 'styled-components';

export const FormUploadList = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 12px;
  
  .radio-wrap {
    display: flex;
    flex-direction: column;

    .error {
      font-weight: 500;
      line-height: 16px;
      font-size: 12px;
      color: ${({ theme }) => theme.colors.error};
    }
  }
`;

export const FormUploadListItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
