import React, { createContext, useState } from 'react';
import Cookies from 'universal-cookie/cjs';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const CityContext = createContext({});

// eslint-disable-next-line react/prop-types
export function CityProvider({ children }) {
  const cookies = new Cookies();
  const fetchedCityCookie = cookies.get('fetched-city');
  const pickedCityCookie = cookies.get('picked-city');
  const [city, setCity] = useState(pickedCityCookie || fetchedCityCookie || '');
  // eslint-disable-next-line func-names
  const pickCity = (newCity) => setCity(newCity);
  return (
  // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CityContext.Provider value={{ city, pickCity }}>
      { children }
    </CityContext.Provider>
  );
}

export default CityContext;
