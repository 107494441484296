import styled from 'styled-components';
import { device } from 'styles/mixins';

export const CalculationItem = styled.section`
  margin-top: 75px;
  display: flex;
  align-items: flex-start;
  
  &:nth-child(2n) {
    padding-left: 50px;
  }

  &:nth-child(even) {
    ${device.lgM`
      div {
        width: 375px;
      }
   `}
  }

  &:nth-child(odd) {
    ${device.lgM`
      div {
        width: 500px;
      }
   `}
  }

  ${device.lgM`
    div {
      width: 375px;
    }
  `}

  ${device.md`
    div {
      width: 243px;
    }
  `}

  &:nth-child(n) {
    ${device.md`
      div {
        width: 243px;
      }
   `}
  }

  ${device.sm`
    &:nth-child(2n) {
      padding-left: 0px;
    }
  `}
`;

export const CalculationItemContent = styled.div`
  width: 500px;
  margin-left: 16px;

  ${device.md`
    width: 375px;
  `}

  ${device.sm`
    width: 243px;
  `}
`;

export const CalculateItemTitle = styled.h3`
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 134.09%;
`;

export const CalculateItemDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 134.09%;
`;
export const CalculateItemIcon = styled.div`
  ._prof {
    width: 34px;
    height: 36px;
  }

  ._order {
    width: 43px;
    height: 36px;
  }

  ._individual {
    width: 38px;
    height: 36px;
  }

  ._support {
    width: 38px;
    height: 36px;
  }
`;
