import React from 'react';
import { HeaderLogo, HeaderLogoImg } from './style';

export function AppHeaderLogo() {
  return (
    <HeaderLogo>
      <a href="https://petrovich.ru/">
        <HeaderLogoImg src="img/logo.png" alt="Петрович" />
      </a>
    </HeaderLogo>
  );
}
