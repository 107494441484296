import styled from 'styled-components';
import { device } from 'styles/mixins';

export const FooterLogos = styled.div`
  display: flex;
  flex-shrink: 0;
  align-content: center;

  ${device.sm`
    justify-content: center;
  `}
`;

export const FooterLogoButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.white80};
  border-radius: 4px;
  
  &:last-child {
    margin-right: 40px;
  }
  
  &:hover {
    cursor: pointer;
    svg {
      opacity: .8;
    }
  }
  
  ${device.sm`
    &:last-child {
      margin-right: 0;
    }
  `}
`;
