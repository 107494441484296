import React from 'react';
import Select, {
  GroupBase, OptionsOrGroups,
} from 'react-select';
import { IOptions } from 'components/form/FormFields';
import { Controller } from 'react-hook-form';
import { FormSelect } from './style';

interface SelectProps {
  options: OptionsOrGroups<IOptions, GroupBase<IOptions>>
  placeholder: string
  required?: boolean
  name?: string
  control: any
  error?: string
  half?: boolean
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: string) => void
}

function AppFormSelect({
  options, placeholder, name, error, required, half, control, onChange,
}: SelectProps) {
  const handleChange = (newSelect: string) => {
    if (newSelect && onChange) {
      onChange(newSelect);
    }
  };

  const customStyles = {
    clearIndicator: () => ({
      display: 'none',
    }),
    control: () => ({
      display: 'flex',
      borderRadius: '6px',
      border: error ? '1px solid #ed1c24' : '1px solid #c4d2d9',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: '15px 16px 13px',
    }),
    input: (provided: any) => ({
      ...provided,
      padding: '0',
      margin: '0',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      transition: 'all .2s ease',
      transform: state.isFocused && !state.isSelected ? 'rotate(180deg)' : null,
    }),
    option: (provided: any, selectProps: any) => ({
      ...provided,
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: selectProps.isSelected
        ? '#eff3f6'
        : selectProps.isFocused
          ? 'transparent'
          : undefined,
      // eslint-disable-next-line no-nested-ternary
      color: selectProps.isSelected
        ? '#444'
        : selectProps.isFocused
          ? '#444'
          : undefined,
      '&:hover': {
        color: '#444',
        backgroundColor: '#eff3f6',
      },
    }),
  };

  return (
    <FormSelect half={!!half}>
      <Controller
        name={name || ''}
        rules={{ required }}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            styles={customStyles}
            isSearchable={false}
            className="formSelect"
            value={field.value}
            onChange={(e) => {
              if (e) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                field.onChange(e);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                handleChange(e.label);
              }
            }}
            options={options}
            placeholder={placeholder}
            blurInputOnSelect
          />
        )}
      />

      {error === 'required' ? (<div className="error">Выберите значение</div>) : ''}
    </FormSelect>
  );
}

AppFormSelect.defaultProps = {
  name: '',
  error: false,
  required: false,
  half: false,
  onChange: '',
};

export { AppFormSelect };
