import { createGlobalStyle } from 'styled-components';
import MontserratLight from 'assets/font/Montserrat-Light.woff';
import MontserratRegular from 'assets/font/Montserrat-Regular.woff';
import MontserratMedium from 'assets/font/Montserrat-Medium.woff';
import MontserratSemiBold from 'assets/font/Montserrat-SemiBold.woff';
import MontserratBold from 'assets/font/Montserrat-Bold.woff';

export default createGlobalStyle`
  @font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), local('Montserrat'),
    url(${MontserratLight}) format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), local('Montserrat'),
    url(${MontserratRegular}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), local('Montserrat'),
    url(${MontserratMedium}) format('woff');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), local('Montserrat'),
    url(${MontserratSemiBold}) format('woff');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), local('Montserrat'),
    url(${MontserratBold}) format('woff');
    font-weight: 700;
    font-style: normal;
  }
`;
