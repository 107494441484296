import styled from 'styled-components';
import { device } from 'styles/mixins';
import { Wrapper } from 'components/global/Wrapper/style';

export const Form = styled.div`
`;

export const FormWrapper = styled(Wrapper)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  
  ${device.sm`
    flex-wrap: wrap;
  `}
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 74px 0 39px 0;
  max-width: 488px;
  line-height: 19px;
  font-size: 14px;

  ${device.md`
    padding: 30px 0 55px 0;
    max-width: 369px;
  `}

  ${device.sm`
    padding: 28px 0 51px 0;
    max-width: 100%;
  `}
`;

export const FormInit = styled.div`
  margin-bottom: 39px;

  ${device.sm`
    margin-bottom: 0;
  `}
`;

export const FormText = styled.p`
  font-weight: 600;
  margin-bottom: 18px;
  font-size: 16px;
  line-height: 134.09%;
  color: ${({ theme }) => theme.colors.black};
`;

export const FormTitle = styled.h2`
  font-weight: 800;
  font-size: 38px;
  color: ${({ theme }) => theme.colors.yellow};
  margin-bottom: 28px;

  ${device.md`
    font-size: 28px;
    margin-bottom: 25px;
  `}

  ${device.sm`
    font-size: 26px;
    margin-bottom: 18px;
  `}
`;

export const FormExclusive = styled.span`
  font-weight: 500;
  line-height: 21px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.lightGray};
  margin-bottom: 17px;
`;

export const FormDescripiton = styled.p`
  line-height: 19px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 37px;

  ${device.md`
    line-height: 19px;
    font-size: 14px;
    margin-bottom: 30px;
  `}

  ${device.sm`
    margin-bottom: 24px;
  `}
`;

export const FormPolicy = styled.span`
  font-weight: 500;
  line-height: 16px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.lightGray};
  margin-right: -60px;

  ${device.sm`
    margin-right: 0;
  `}
`;

export const FormLink = styled.a`
  color: ${({ theme }) => theme.colors.link};
  border-bottom: 1px dashed ${({ theme }) => theme.colors.link};
`;

export const FormCalculate = styled.div`
  margin-bottom: 12px;
  width: 100%;
`;

export const FormRadios = styled.div`
  width: 100%;
  margin: 24px 0 32px;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`;
