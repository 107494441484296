import React from 'react';
import { FooterRatio } from './style';

export function AppFooterRatio() {
  // TODO: сделать компонент подписи для использования в реакте, пока что костыльное решение
  setTimeout(() => {
    if (window && window.panfilov_digital) {
      window.panfilov_digital.PanfilovDigitalSign({
        el: document.querySelector('#panfilov-sign'),
        options: {},
      });
    }
  }, 500);
  return (
    <FooterRatio>
      <div id="panfilov-sign" />
    </FooterRatio>
  );
}
