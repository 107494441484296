import React from 'react';
import InputMask from 'react-input-mask';
import { Controller } from 'react-hook-form';
import { FormInput } from './style';

declare interface InputProps {
    label: string
    required?: boolean
    id: string
    type: string
    name: string
    control: any
    error: boolean
}

export const AppFormInput = React.forwardRef(({
  type, id, required, label, name, control, error,
// eslint-disable-next-line no-unused-vars
}: InputProps, ref) => (
  <FormInput error={error}>
    {
      name === 'phone'
        ? (
          <Controller
            control={control}
            name={name || ''}
            rules={{ required }}
            render={({ field }) => (
              <InputMask
                {...field}
                ref={null}
                mask={name === 'phone' ? '+9 (999) 999 99 99' : ''}
                type={type}
                id={id}
                name={name}
                onChange={(e) => {
                  if (e) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    field.onChange(e.target.value);
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    // setValue(e.target.value);
                  }
                }}
              />
            )}
          />
        )
        : (
          <Controller
            control={control}
            name={name || ''}
            rules={{ required }}
            render={({ field }) => (
              <input
                {...field}
                type={type}
                id={id}
                name={name}
                onChange={(e) => {
                  if (e) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    field.onChange(e.target.value);
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    // setValue(e.target.value);
                  }
                }}
              />
            )}
          />
        )
    }
    <label htmlFor={id}>{label}</label>
  </FormInput>
));

AppFormInput.defaultProps = {
  required: false,
};
