import React from 'react';
import { Wrapper } from 'components/global/Wrapper/style';
import { calcItems } from 'mock/calculationData';
import { AppCalculationItem } from 'components/calculation/CalculationItem';
import { AppCalculationContacts } from 'components/calculation/CalculationContacts';
import {
  Calculation, CalculationContent, CalculationPrice, CalculationTitle, CalculationWrapper,
} from './style';

export function AppCalculation() {
  return (
    <Calculation>
      <Wrapper>
        <CalculationContent>
          <CalculationTitle>Расчет материалов</CalculationTitle>
          <CalculationPrice>УСЛУГА БЕСПЛАТНАЯ</CalculationPrice>
          <CalculationWrapper>
            {
                calcItems.map((item) => (
                  <AppCalculationItem
                    key={item.id}
                    icon={item.icon}
                    description={item.description}
                    iconHeight={item.iconHeight}
                    iconWidth={item.iconWidth}
                    title={item.title}
                  />
                ))
              }
          </CalculationWrapper>
          <AppCalculationContacts />
        </CalculationContent>
      </Wrapper>
    </Calculation>
  );
}
