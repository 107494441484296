import styled from 'styled-components';
import { device } from '../../../styles/mixins';

export const FormFields = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 8px;
  line-height: 19px;
  font-size: 14px;
`;

export const FormButton = styled.button`
  margin-top: 10px;
  outline: none;
  border: 0;
  padding: 0;
  background: #f6b715;
  border-radius: 6px;
  width: 165px;
  height: 52px;
  cursor: pointer;
  text-transform: uppercase;
  line-height: 19px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;

  &:hover {
    background: #ffd65c;
  }
`;

export const FormInputWrapper = styled.div`
  width: calc(100% / 2 - 7px);

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  ${device.md`
    width: 100%;
  `}
`;
