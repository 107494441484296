import React, { useContext, useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { cities } from 'mock/formCities';
import { addresses } from 'mock/formAddresses';
import { radioList } from 'mock/formRadioList';
import { formYouAre } from 'mock/formYouAre';
import { formCalculationPurpose } from 'mock/formCalculationPurpose';
import { AppModal } from 'components/global/AppModal';
import CityContext from 'context/cityContext';
import Cookies from 'universal-cookie';
import { FormFields, FormButton, FormInputWrapper } from './style';
import { AppFormInput } from '../FormInput';
import { AppFormSelect } from '../FormSelect';
import { AppFormRadioList } from '../FormRadios';
import { AppFormUploadList } from '../FormUploadList';
import { FormTextarea } from '../FormTextarea/style';

interface FormValues {
    name: string
    phone: string
    email: string
    card: string
    city: string
    clientType: string
    calculationPurpose: string
    address: string
    comment: string
    calculationType: string
    otherMaterial: string
    metalTileSize: string
    radioList: string
    roofMaterial: string
    wallMaterial: string
    facadeMaterial: string
    repairObject: string
    buildingObject: string
    roofFile: FileList | null
    wallFile: FileList | null
    facadeFile: FileList | null
    otherMaterialFile: FileList | null
    repairFile: FileList | null
    buildingFile: FileList | null
}

export interface IOptions {
  value: string
  label: string
}

export function AppFormFields() {
  const {
    register, handleSubmit, formState: { errors }, control, setValue,
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      card: '',
      city: '',
      comment: '',
      clientType: '',
      calculationPurpose: '',
      address: '',
      calculationType: '',
      metalTileSize: '',
      otherMaterial: '',
      wallMaterial: '',
      facadeMaterial: '',
      repairObject: '',
      buildingObject: '',
      roofFile: null,
      wallFile: null,
      facadeFile: null,
      otherMaterialFile: null,
      repairFile: null,
      buildingFile: null,
    },
  });
  const ref = React.createRef();
  const cookies = new Cookies();
  const [modalShow, setModalShow] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(true);
  const [repairType, setRepairType] = useState<string>('');
  const [pickedCity, setPickedCity] = useState<string>('');

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { city, pickCity } = useContext(CityContext);

  function resetAddress() {
    setValue('address', '');
  }

  function getAddresses() {
    const objCity = cities.find((item) => item.label === pickedCity);
    if (objCity) {
      const currentAddresses = addresses
        .filter((address) => {
          const cityCode = address.value.match(/^(\w+)_(\w+)/);
          if (cityCode) {
            // сравниваем 'spb' из cities и первую часть кода адреса: 'spb' из, например, 'spb_kad'
            return cityCode[1] === objCity.value;
          }
          return false;
        });
      if (currentAddresses) {
        if (currentAddresses.length === 1) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setValue('address', currentAddresses[0]);
        }
        return currentAddresses;
      }
    }
    return addresses;
  }

  function initCity() {
    const fetchedCityCookie = cookies.get('fetched-city');
    const pickedCityCookie = cookies.get('picked-city');
    const lookupCity = pickedCityCookie || fetchedCityCookie || city;
    const currentCity = cities.find((item) => item.label === lookupCity);
    return currentCity || cities[0];
  }

  useEffect(() => {
    const currentCity = initCity();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setValue('city', currentCity);
    setPickedCity(currentCity.label);
    getAddresses();
  });

  function setPickedCityCookie(pickedCityCookie: React.SetStateAction<string>) {
    cookies.set('picked-city', pickedCityCookie, { maxAge: 60 * 60 * 24 * 365 });
    pickCity(pickedCityCookie);
  }

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const parsedData = {
      ...data,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      city: data.city.label,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      address: data.address.value,
      token: process.env.REACT_APP_AUTH_TOKEN,
    };
    const formData = new FormData();

    // eslint-disable-next-line no-restricted-syntax
    for (const [k, v] of Object.entries(parsedData)) {
      // пропускаем radioList, которое добавлено в FormValues, чтобы валидировать AppFormRadioList
      if (v === null) {
        // eslint-disable-next-line no-continue
        continue;
      }
      if (typeof v === 'object' && 'value' in v) {
        formData.append(k, v.value);
      } else if (k.indexOf('File') !== -1) {
        for (let i = 0; i < v.length; i += 1) {
          formData.append(`${k}[]`, v[i]);
        }
      } else {
        formData.append(k, v);
      }
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.grecaptcha.ready(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.grecaptcha.execute('6Le6blEmAAAAADv1MWF1drXdVZhaUjHzoT2WVp4_', { action: 'submit' }).then(async (token) => {
        formData.append('recaptchaToken', token);
        try {
          const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/feedback/index.php`, {
            method: 'POST',
            headers: {
              Authorization: `Basic ${process.env.REACT_APP_AUTH_BASIC}`,
              Accept: '*/*',
            },
            body: formData,
            redirect: 'follow',
          });

          if (res.status === 200) {
            const dataLayer = window.dataLayer || [];
            dataLayer.push({ event: 'Shopexpert event', eventCategory: 'Shopexpert', eventAction: 'Отправка формы' });
            setModalSuccess(true);
            setValue('email', '');
            setValue('name', '');
            setValue('phone', '');
            setValue('city', '');
            setValue('card', '');
            setValue('address', '');
            setValue('comment', '');
            setValue('clientType', '');
            setValue('calculationPurpose', '');
            setValue('calculationType', '');
            setValue('metalTileSize', '');
            setValue('otherMaterial', '');
            setValue('roofMaterial', '');
            setValue('wallMaterial', '');
            setValue('facadeMaterial', '');
            setValue('repairObject', '');
            setValue('buildingObject', '');
            setValue('roofFile', null);
            setValue('wallFile', null);
            setValue('facadeFile', null);
            setValue('otherMaterialFile', null);
            setValue('repairFile', null);
            setValue('buildingFile', null);
            setRepairType('');
          } else {
            setModalSuccess(false);
          }
          setModalShow(true);
        } catch (err) {
          setModalSuccess(false);
          setModalShow(true);
        }
      });
    });
  };

  const errorStyle = {
    fontWeight: 500,
    lineHeight: '16px',
    fontSize: '12px',
    color: '#ed1c24',
    marginTop: '-10px',
    marginBottom: '10px',
  };

  return (
    <FormFields onSubmit={handleSubmit(onSubmit)}>
      <FormInputWrapper>
        <AppFormInput
          id="name"
          required
          type="text"
          label="Имя"
          control={control}
          error={errors?.name?.type === 'required'}
          {...register('name', { required: true })}
          ref={ref}
        />
        {errors.name && errors.name.type === 'required' && <div style={errorStyle}>Заполните поле</div>}
      </FormInputWrapper>
      <FormInputWrapper>
        <AppFormInput
          id="phone"
          required
          type="tel"
          label="Телефон"
          control={control}
          error={errors?.phone?.type === 'required' || errors?.phone?.type === 'pattern'}
          {...register('phone', {
            required: true,
            pattern: {
              value: /^\+\d\s\(\d+\)\s\d+\s\d\d\s\d\d$/,
              message: 'Телефон введен неверно',
            },
          })}
          ref={ref}
        />
        {errors.phone && errors.phone.type === 'required' && <div style={errorStyle}>Заполните поле</div>}
        {errors.phone && errors.phone.type === 'pattern' && <div style={errorStyle}>Неверно введен телефон</div>}
      </FormInputWrapper>
      <FormInputWrapper>
        <AppFormInput
          id="email"
          required
          type="email"
          label="Эл. почта"
          control={control}
          error={errors?.email?.type === 'required' || errors?.email?.type === 'pattern'}
          {...register('email', {
            required: true,
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Email введен неверно',
            },
          })}
          ref={ref}
        />
        {errors.email && errors.email.type === 'required' && <div style={errorStyle}>Заполните поле</div>}
        {errors.email && errors.email.type === 'pattern' && <div style={errorStyle}>Email введен неверно</div>}
      </FormInputWrapper>
      <FormInputWrapper>
        <AppFormInput
          id="card"
          type="tel"
          label="№ Карты Клуба Друзей"
          control={control}
          error={false}
          {...register('card', { required: false })}
          ref={ref}
        />
        {errors.card && errors.card.type === 'required' && <div style={errorStyle}>Заполните поле</div>}
      </FormInputWrapper>
      <AppFormSelect
        options={cities}
        placeholder="Город"
        name="city"
        required
        error={errors?.city?.type}
        control={control}
        onChange={(e: React.SetStateAction<string>) => {
          setPickedCity(e);
          resetAddress();
          setPickedCityCookie(e);
        }}
      />
      <AppFormSelect
        options={getAddresses()}
        placeholder="Адрес строительного центра"
        name="address"
        required
        control={control}
        error={errors?.address?.type}
      />
      <AppFormRadioList
        {...register('calculationType', { required: true })}
        ref={ref}
        control={control}
        error={errors?.calculationType?.type}
        radioList={radioList}
        onChange={(e: React.SetStateAction<string>) => setRepairType(e)}
      />
      <AppFormUploadList
        name="metalTileSize"
        selectedType={repairType}
        register={register}
        control={control}
        error={errors?.metalTileSize?.type}
      />
      <AppFormSelect
        options={formCalculationPurpose}
        placeholder="Расчет необходим для"
        name="calculationPurpose"
        control={control}
        error={errors?.calculationPurpose?.type}
      />
      <AppFormSelect
        options={formYouAre}
        placeholder="Вы являетесь"
        name="clientType"
        control={control}
        error={errors?.clientType?.type}
      />
      <FormTextarea
        {...register('comment')}
        placeholder="Комментарий"
        rows={4}
      />
      <FormButton type="submit">Отправить</FormButton>
      <AppModal success={!!modalSuccess} show={!!modalShow} onChange={(e) => setModalShow(e)} />
    </FormFields>
  );
}
