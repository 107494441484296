export const baseTheme = {
  colors: {
    red: '#b11116',
    error: '#ed1c24',
    blue: '#1d3989',
    yellow: '#F0AF0C',
    white: '#FFFFFF',
    white90: '#F3F5F5',
    white80: '#f0f2f2',
    lightGray: '#b0b0b0',
    gray: '#c4d2d9',
    darkGray: '#7a8891',
    darkerGray: '#444444',
    black: '#000000',
    disabled: '#d3d3d3',
    blackTransparent: 'rgba(0,0,0,.4)',
    link: '#8ea0ce',
  },

  sizes: {
    header: { height: '64px' },
    wrapper: { maxWidth: '1170px' },
  },

  media: {
    m: '480px',
    sm: '767px',
    md: '1219px',
    lgM: '1320px',
  },
};
