import React from 'react';
import { ReactSVG } from 'react-svg';

declare interface IconProps {
    name: string,
    width?: number,
    height?: number,
    className?: string,
}

// svg должны лежать в /public
function AppIcon(props: IconProps) {
  const { name, width, height } = props;
  return (
    <ReactSVG {...props} src={`${name}.svg`} style={{ width: `${width}px`, height: `${height}px` }} />
  );
}

AppIcon.defaultProps = {
  width: 20,
  height: 20,
  className: '',
};

export { AppIcon };
