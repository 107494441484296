export const supportedCities: Array<string> = [
  'Москва',
  'Санкт-петербург',
  'Тверь',
  'Чебоксары',
  'Набережные Челны',
  'Киров',
  'Казань',
  'Нижний Новгород',
  'Псков',
  'Саратов',
];
