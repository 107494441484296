export const formCalculationPurpose = [
  {
    value: 'Оформления заказа',
    label: 'Оформления заказа',
  },
  {
    value: 'Консультации',
    label: 'Консультации',
  },
  {
    value: 'Подобрать материалы из ассортимента Петровича',
    label: 'Подобрать материалы из ассортимента Петровича',
  },
  {
    value: 'Узнать предварительную стоимость',
    label: 'Узнать предварительную стоимость',
  },
  {
    value: 'Другое',
    label: 'Другое',
  },
];
