import styled from 'styled-components';
import { device } from 'styles/mixins';

export const Wrapper = styled.section`
  max-width: ${({ theme }) => theme.sizes.wrapper.maxWidth};
  margin: 0 auto;
  position: relative;
  
  ${device.lgM`
    padding: 0 20px;
  `}
  
  ${device.md`
    max-width: 1000px;
    padding: 0 50px;
  `}
  
  ${device.sm`
    max-width: 700px;
    padding: 0 20px;
  `}
`;

export const HeaderWrapper = styled(Wrapper)`
  height: 100%;
  position: static;
  
  ${device.sm`padding: 0;`}
`;

export const FooterWrapper = styled(Wrapper)`
  width: 100%;
`;
