import React from 'react';
import { ReactComponent as ClockIcon } from 'assets/ico/clock.svg';
import { CalculationContactsItem, CalculationContactsItemTime, CalculationContactsItemTitle } from './style';

interface ContactItemProps {
    title: string,
    times: Array<string>,
}

export function AppCalculationContactsItem({ title, times }: ContactItemProps) {
  return (
    <CalculationContactsItem>
      <CalculationContactsItemTitle dangerouslySetInnerHTML={{ __html: title }} />
      {
            times.map((item: string, index: number) => (
              <CalculationContactsItemTime key={index}>
                { index === 0 && <ClockIcon style={{ width: '20px', height: '20px', marginRight: '10px' }} />}
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: item }} />
              </CalculationContactsItemTime>
            ))
        }
    </CalculationContactsItem>
  );
}
